import FiveGridLine_DottedLineComponent from '@wix/thunderbolt-elements/src/components/FiveGridLine/viewer/skinComps/DottedLine/DottedLine.skin';


const FiveGridLine_DottedLine = {
  component: FiveGridLine_DottedLineComponent
};


export const components = {
  ['FiveGridLine_DottedLine']: FiveGridLine_DottedLine
};

